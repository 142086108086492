<template>
  <loader v-if="loader" />
  <div class="add-ads main-padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-9 mx-auto">
          <div class="add-form section-style sec-padding">
            <div class="row">
              <div class="col-lg-8 col-md-10 col-11 mx-auto">
                <!-- form  -->
                <form ref="uploadAdForm" enctype="multipart/form-data">
                  <h2 class="section-title">ارفع إعلانك</h2>
                  <div class="inputs-container">
                    <div class="upload-ads-img">
                      <span class="ads-img-text">
                        ارفع صور الإعلان
                        <span class="upload-text">بحد اقصى 6 صور</span>
                      </span>
                      <div class="upload-img-container">
                        <div class="upload-img">
                          <input
                            type="file"
                            accept="image/*"
                            multiple
                            id="imgUpload"
                            class="hidden-input places-imgs-input"
                            @change="uploadAdImages($event.target)"
                          />
                          <div class="img-upload-show">
                            <label for="imgUpload" class="label-img">
                              <img
                                :src="camImage"
                                alt=""
                                v-bind:ref="'image' + parseInt(key)"
                              />
                            </label>
                          </div>
                        </div>
                        <div class="img-upload-show mt-4">
                          <div class="d-flex">
                            <div class="hidden-img" v-if="adsImages.length <= 6">
                              <div class="position-relative" v-for="(image, key) in adsImages" :key="key">
                                <button class="remove-img text-white" type="button" @click="removeImage(image, key)">
                                  &times;
                                </button>
                                <img class="preview img-thumbnail" :src="image" ref="image" />
                              </div>
                            </div>
                          </div>
                          <div v-if="showValid" class="imageAlert">
                            <v-alert type="error">غير مسموح بأكثر من 6 صور</v-alert>
                          </div>
                        </div>

                      </div>
                    </div>

                    <div class="input-g">
                      <label for="" class="main-label"> اسم الإعلان </label>
                      <div class="main-input">
                        <input
                          type="text"
                          class="input-me"
                          placeholder="أدخل اسم الإعلان"
                          name="name"
                          v-model="name"
                        />
                      </div>
                    </div>

                    <div class="input-g">
                      <label for="" class="main-label"> سعر الإعلان </label>
                      <div class="main-input">
                        <input
                          type="number"
                          class="input-me"
                          placeholder="أدخل سعر الإعلان"
                          name="price"
                          v-model="price"
                        />
                      </div>
                    </div>
<!-- 
                    <div class="input-g">
                      <label for="" class="main-label"> مدة الإعلان (المدة بالايام) </label>
                      <div class="main-input">
                        <input
                          type="number"
                          class="input-me"
                          placeholder="أدخل مدة عرض الإعلان"
                          name="duration"
                          v-model="duration"
                        />
                      </div>
                    </div> -->
                    <!-- القسم الرئيسيي -->
                    <div class="input-g">
                      <label for="" class="main-label"> القسم الرئيسي </label>
                      <div class="main-input">
                        <select
                          name="category_id"
                          id=""
                          class="input-me select"
                          @change="getCatId()"
                          v-model="category_id"
                        >
                          <option value="" selected disabled hidden>
                            اختر القسم الرئيسي
                          </option>
                          <option
                            :value="cat.id"
                            v-for="cat in categories"
                            :key="cat.id"
                          >
                            {{ cat.name }}
                          </option>
                        </select>
                        <i class="fa-solid fa-angle-down main-icon"></i>
                      </div>
                    </div>
                    <!-- المدينة -->
                    <div class="input-g">
                      <label for="" class="main-label">المدينة</label>
                      <div class="main-input">
                        <select
                          name="city_id"
                          id=""
                          class="input-me select"
                          v-model="city_id"
                        >
                          <option value="" selected disabled hidden>
                            اختر المدينة
                          </option>
                          <option
                            :value="city.id"
                            v-for="city in cities"
                            :key="city.id"
                          >
                            {{ city.name }}
                          </option>
                        </select>
                        <i class="fa-solid fa-angle-down main-icon"></i>
                      </div>
                      <div>
                        <span>اذا لم تجدالمدينه</span>
                        <button
                          data-bs-toggle="modal"
                          data-bs-target="#addCity"
                          type="button"
                          style="color: #2abdc7"
                        >
                          اضغط هنا
                        </button>
                      </div>
                    </div>
                    <!-- <div class="input-g">
                      <label for="" class="main-label"> المدينة </label>
                      <div class="main-input position-relative">
                        <div class="position-relative w-100">
                            <input type="text" v-model="regionQuery"  name="query" placeholder="اختر المدينة" class="form-control select input-me" @input="getCities()">
                        </div>
                        <i class="fa-solid fa-angle-down main-icon"></i>
                        <div v-if="showList1" style="position:absolute;width:100%;height:200px;overflow-y:auto;top:50px;z-index:999">
                          <ul class="list-group" style="z-index:9999" v-if="filteredRegions.length>0">
                              <li class="list-group-item" v-for="(item,index) in filteredRegions" :key="item.id" >
                                      <span @click="setNewValueForRegion(item.name, item.id)" style="cursor:pointer" >
                                          {{index+1}}.{{item.name}}
                                      </span>
                              </li>
                          </ul>
                          <ul class="list-group" v-else>
                              <li class="list-group-item"> لا تتوفر بيانات</li>
                          </ul>
                        </div>
                      </div>

                      <div class="mt-3">
                        <span> لا توجد مدينة ؟ 
                          <span class="addCity" data-bs-toggle="modal"
                          data-bs-target="#addCity">اضف مدينة</span> 
                        </span>
                      </div>
                    </div> -->
                    <div class="input-g">
                      <label for="" class="main-label"> تفاصيل الإعلان </label>
                      <div class="main-input">
                        <textarea
                          name="details"
                          v-model="details"
                          class="input-me text-area"
                          placeholder="اكتب تفاصيل الإعلان"
                        ></textarea>
                      </div>
                    </div>

                    <div class="input-g">
                      <label for="" class="main-label"> وسيلة اتصال </label>
                      <div class="main-input">
                        <input
                          name="contact_method"
                          v-model="contact_method"
                          type="text"
                          class="input-me"
                          placeholder="أدخل رقم الجوال أو البريد الالكتروني"
                        />
                      </div>
                    </div>

                    <div class="input-g">
                      <label for="" class="main-label"> حالة المنتج </label>
                      <div class="radio-boxs">
                        <div class="box">
                          <input
                            type="radio"
                            name="productStatus"
                            v-model="productStatus"
                            value="new"
                            id="da3ef"
                          />
                          <label for="da3ef">جديد</label>
                        </div>
                        <div class="box">
                          <input
                            type="radio"
                            name="productStatus"
                            v-model="productStatus"
                            value="used"
                            id="maqbool"
                          />
                          <label for="maqbool">مستعمل</label>
                        </div>
                      </div>
                    </div>

                    <div class="input-g">
                      <button
                        type="submit"
                        class="main-btn md up mx-auto"
                        :disabled="disabled"
                        @click.prevent="uploadAdv()"
                      >
                        استمرار
                        <div
                          class="spinner-border"
                          role="status"
                          v-if="disabled"
                        >
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="addCity"
    aria-hidden="true"
    aria-labelledby="exampleModalToggleLabel2"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <button
          type="button"
          class="close-model-btn"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <i class="fa-regular fa-circle-xmark"></i>
        </button>

        <div class="content-model-me">
          <div class="modal-header">
            <h2 class="section-title">إضافة مدينة</h2>
          </div>

          <form action="" class="modal-form" ref="sendCode">
            <div class="modal-body">
              <div class="inputs-container">
                <div class="input-g">
                  <label for="" class="main-label"> اسم المدينة </label>
                  <div class="main-input">
                    <input
                      type="text"
                      class="input-me"
                      placeholder="أدخل اسم المدينة"
                      name="city_name"
                      v-model="city_name"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button class="main-btn md up blabla" @click.prevent="addNewCity">
                إرسال
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import loader from "../../components/Shared/pageLoader.vue";

export default {
  data() {
    return {
      camImage: require("../../assets/imgs/photo-camera.png"),
      // form inputs
      productStatus: "",
      contact_method: "",
      details: "",
      city: "",
      sub_category_id: "",
      category_id: "",
      duration: "",
      price: "",
      name: "",

      imags: [],
      loader: true,
      addCity: false,

      // images previewed
      adsImages: [],
      adsImagesName: [],
      showValid: false,

      // get categories
      categories: [],
      // get sub_category_id
      subCategories: [],

      // cities
      cities: [],
      city_id: null,

      // loading pagination handle
      regionQuery: null,

      disabled: false,
      city_name: "",
    };
  },

  computed: {
    filteredRegions() {
      return this.cities.filter((region) => {
        return region.name
          .toLowerCase()
          .includes(this.regionQuery.toLowerCase());
      });
    },
  },
  components: {
    loader,
  },
  methods: {
    async addNewCity() {
      const fd = new FormData();
      fd.append("name[ar]", this.city_name);
      await axios
        .post("new-city", fd, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.data?.key == "success") {
            this.$swal({
              icon: "success",
              title: res.data.msg,
              timer: 2000,
              showConfirmButton: false,
            });
            document.getElementById("addCity").style.display = "none";
            document.querySelector(".modal-backdrop").style.display = "none";
            setTimeout(() => {
              this.getCities().then(() => {
                // console.log(this.cities);
              });
            }, 0);
          } else {
            this.$swal({
              icon: "error",
              title: res.data.msg,
              timer: 2000,
              showConfirmButton: false,
            });
          }
        });
    },
    // image preview
    uploadAdImages(file) {
      const selectedImages = file.files;

      // Clear previous images and names
      this.adsImages = [];
      this.adsImagesName = [];

      if (selectedImages.length > 6) {
        this.showValid = true; // Show validation if more than 6 images
        return; // Exit if too many images are selected
      } else {
        this.showValid = false; // Hide validation message
      }

      const promises = Array.from(selectedImages).map((image) => {
        return new Promise((resolve) => {
          const reader = new FileReader(); // Create a FileReader instance

          reader.onload = (event) => {
            const base64Image = event.target.result; // Get base64 image
            this.adsImages.push(base64Image); // Store base64 image for display
            this.adsImagesName.push(image.name); // Store file name

            // Store base64 image in localStorage
            const storedImages = JSON.parse(localStorage.getItem('adsImages')) || [];
            storedImages.push(base64Image);
            localStorage.setItem('adsImages', JSON.stringify(storedImages)); // Store in localStorage

            resolve(); // Resolve the promise
          };

          reader.readAsDataURL(image); // Convert file to base64 string
        });
      });

      // Wait for all images to be processed
      Promise.all(promises).then(() => {
        this.applyImage(); // Call your function to apply the images
      });
    },


    

    // remvoe image
    removeImage(image, key) {
      // Remove image from adsImages
      this.adsImages.splice(key, 1);
      this.adsImagesName.splice(key, 1); // Remove the corresponding name

      // Update localStorage
      const storedImages = JSON.parse(localStorage.getItem('adsImages')) || [];
      storedImages.splice(key, 1); // Remove the image from the stored array
      localStorage.setItem('adsImages', JSON.stringify(storedImages)); // Update localStorage

      this.applyImage(); // Call your function to update the displayed images
    },


    applyImage() {
      for (let i = 0; i < this.adsImages.length; i++) {
        this.$refs.image[i].src = this.adsImages[i]; // Update image previews
      }
    },


    // submit upload ads form
    async uploadAdv() {
      // this.disabled = true;
      // const fd = new FormData(this.$refs.uploadAdForm);
      // fd.append("city_id", this.city_id);

      // for (var i = 0; i < this.adsImages.length; i++) {
      //   this.imags = this.adsImages[i];
      //   fd.append("image[]", this.imags);
      // }

      // console.log(this.imags)
      const handleValidationError =(fieldName) =>{
        this.$swal({
          icon: "error",
          title: `يجب إضافه ${fieldName} للاستمرار`,
          timer: 1000,
          showConfirmButton: false,
        });
      }
      window.localStorage.setItem('bannerOrAd' , 'ad')
      window.localStorage.setItem('ad_name' , this.name)
      window.localStorage.setItem('ad_name' , this.name)
      window.localStorage.setItem('ad_price' , this.price)
      // window.localStorage.setItem('ad_duration' , this.duration)
      window.localStorage.setItem('ad_category_id' , this.category_id)
      // window.localStorage.setItem('ad_sub_category_id' , this.sub_category_id)
      window.localStorage.setItem('ad_city_id' , this.city_id)
      window.localStorage.setItem('ad_details' , this.details)
      window.localStorage.setItem('ad_contact_method' , this.contact_method)
      window.localStorage.setItem('ad_status' , this.productStatus)
      
      if(this.adsImages.length === 0){
        handleValidationError('صور الإعلان')
      }else if(this.name === ''){
        handleValidationError('إسم الاعلان')
      }else if(this.price === ''){
        handleValidationError('سعر الإعلان')
      }else if(this.category_id === ''){
        handleValidationError('القسم الرئيسي')
      }else if(this.city_id === null){
        handleValidationError('المدينه')
      }else if(this.details === ''){
        handleValidationError('تفاصيل الإعلان')
      }else if(this.contact_method === ''){
        handleValidationError('طريقة التواصل')
      }else if(this.productStatus === ''){
        handleValidationError('حالة الإعلان')
      }else{
        this.$swal({
          icon: "success",
          title: 'تم اضافة الاعلان بنجاح برجاء الدفع',
          timer: 1000,
          showConfirmButton: false,
        });
        setTimeout(() => {
          this.$router.push("/adsPayment");
        }, 800);
      }
    },

    // get categories to filter
    async getCategories() {
      await axios.get("categories").then((res) => {
        this.categories = res.data.data;
      });
    },

    // get subCategories
    async getCatId() {
      await axios.get(`categories/${this.category_id}`).then((res) => {
        this.subCategories = res.data.data;
      });
    },

    // get cities
    async getCities() {
      this.showList1 = true;
      await axios.get("cities").then((res) => {
        this.cities = res.data.data;
      });
    },

    setNewValueForRegion(value1, id) {
      this.regionQuery = value1;
      this.city_id = id;
      if (this.regionQuery == value1) {
        this.showList1 = false;
      }
      // console.log(this.regionQuery)

      // this.getStores()
    },
  },
  mounted() {
    this.getCategories();
    this.getCities();
    this.loader = false;
  },
};
</script>

<style>
.imageAlert {
  color: red;
  display: flex;
}
.addCity {
  color: #2abdc7;
  cursor: pointer;
}
</style>
